<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Main Meter</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <label for="mode">Mode</label>
                  <v-radio-group
                    v-model="form.mode"
                    row
                  >
                    <v-radio
                      label="Manual"
                      :value="0"
                    ></v-radio>
                    <v-radio
                      label="Automatic"
                      :value="1"
                    ></v-radio>
                  </v-radio-group>

                  <small
                    v-show="form.errors.has('mode')"
                    class="validation-error"
                  >{{ form.errors.get('mode') }}</small>
                </v-col>
                <v-expand-transition>
                  <div
                    v-show="showAutomaticMeterFields"
                    class="automatic"
                  >
                    <v-col cols="12">
                      <v-select
                        v-model="form.type_id"
                        mt-50
                        :items="types"
                        item-text="name"
                        item-value="id"
                        label="Type"
                        :loading="isTypesLoading"
                      ></v-select>

                      <small
                        v-show="form.errors.has('type_id')"
                        class="validation-error"
                      >{{
                        form.errors.get('type_id')
                      }}</small>
                    </v-col>
                    <v-col
                      v-show="action === 'Save'"
                      cols="6"
                    >
                      <label for="mode">Valve Status</label>
                      <v-switch
                        v-model="form.valve_status"
                        inset
                        :false-value="0"
                        :true-value="1"
                        :label="form.valve_status ? 'Open' : 'Close'"
                      ></v-switch>

                      <small
                        v-show="form.errors.has('valve_status')"
                        class="validation-error"
                      >{{
                        form.errors.get('valve_status')
                      }}</small>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="form.sim_card_number"
                        label="Sim card number (Optional)"
                        placeholder="07******"
                        :rules="meterPhoneRules"
                        required
                      ></v-text-field>

                      <small
                        v-show="form.errors.has('sim_card_number')"
                        class="validation-error"
                      >{{
                        form.errors.get('sim_card_number')
                      }}</small>
                    </v-col>
                  </div>
                </v-expand-transition>
                <v-col cols="8">
                  <v-text-field
                    v-model="form.number"
                    label="Meter number"
                    placeholder="00000000"
                    :rules="meterNumberRules"
                    number
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('number')"
                    class="validation-error"
                  >{{
                    form.errors.get('number')
                  }}</small>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-if="action === 'Save'"
                    v-model="form.last_reading"
                    label="Last reading"
                    placeholder="130"
                    :rules="meterReadingRules"
                    hint="The current meter reading"
                    number
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('last_reading')"
                    class="validation-error"
                  >{{
                    form.errors.get('last_reading')
                  }}</small>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="form.station_id"
                    mt-50
                    :items="stations"
                    item-text="name"
                    item-value="id"
                    label="Station"
                    :loading="isStationsLoading"
                  ></v-select>

                  <small
                    v-show="form.errors.has('station_id')"
                    class="validation-error"
                  >{{
                    form.errors.get('station_id')
                  }}</small>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import axios from 'axios'
import { mapGetters } from 'vuex'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules],
  props: {
    showDialog: {
      type: Boolean,
    },
    meter: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      number: '',
      station_id: '',
      mode: 1,
      meter_id: '',
      type_id: '',
      last_reading: '',
      valve_status: 1,
      main_meter: true,
      has_location_coordinates: 0,
    }),
    stations: [],
    types: [],
    isStationsLoading: true,
    isTypesLoading: true,
    action: 'Save',
  }),
  computed: {
    ...mapGetters(['selectedStation']),
    meters_url() {
      return `meters?station_id=${this.selectedStation}`
    },
    meterNumberRule() {
      const rules = [
        v => !!v || 'Meter number is required',
        v => Number.isInteger(Number(v)) || 'Meter number must be an integer number',
      ]
      if (this.form.mode === 0 || this.form.mode === '' || this.getSelectedTypeName() === 'Prepaid') {
        return rules
      }
      rules.push(v => !v || v.startsWith('0000') || 'Meter number must start with four zeros')

      return rules
    },
    showAutomaticMeterFields() {
      return this.form.mode === 1
    },
  },
  watch: {
    meter() {
      if (Object.keys(this.meter).length > 0) {
        this.form.update(this.meter)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
    showDialog(value) {
      if (value && this.stations.length === 0) {
        this.getStations()
        this.getTypes()
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
      this.form.valve_status = 0
    },
    getSelectedTypeName() {
      const { types } = this
      let name = null

      // TODO:: replace foreach with an alternative that can break
      types.forEach(type => {
        if (type.id === this.form.type_id) {
          name = type.name
        }
      })

      return name
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveMeter()
        } else {
          this.updateMeter()
        }
      }
    },
    getStations() {
      this.isStationsLoading = true
      axios
        .get('meter-stations')
        .then(response => {
          this.stations = response.data
          this.form.station_id = this.selectedStation
          this.isStationsLoading = false
        })
        .catch(error => {
          this.isStationsLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    getTypes() {
      this.isTypesLoading = true
      axios
        .get('meter-types')
        .then(response => {
          this.types = response.data
          this.isTypesLoading = false
        })
        .catch(error => {
          this.isTypesLoading = false
          this.$notification.error(error.response.data.message)
        })
    },
    saveMeter() {
      this.form
        .post('main-meters')
        .then(() => {
          this.$notification.success('Main meter added successfully')
          this.$emit('close')
          this.$emit('meter-added')
          this.$refs.form.reset()
          this.form.valve_status = 0
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
    updateMeter() {
      this.form
        .put(`meters/${this.form.id}`)
        .then(() => {
          this.$notification.success('Main meter updated successfully')
          this.$emit('close')
          this.$emit('meter-added')
          this.$refs.form.reset()
          this.form.valve_status = 0
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
